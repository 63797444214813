<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p(v-html="exp.expDesc")
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import BackHelp from "../../../components/BackHelp";
import Footer from "../../../components/TheFooter";
export default {
  name: "ProductListing",
  components: { Footer, BackHelp, Navbar },
  data() {
    return {
      ContactList: [
        {
          title: "3.2 How to set up my product/service listings",
          description: [
            {
              text: "1. Click the “Manage” at the top of right corner",
              imgType: true,
              img: "200.Website profile – empty profile – 5.jpg",
            },
            {
              text: "2. Select “Your listing”",
              imgType: true,
              img: "350. Manage website.jpg",
            },
            {
              text: "3. Click “+Add listing” and select photo from gallery or camera",
              imgType: true,
              img: "250.Website-listing – 8.jpg",
            },
            {
              text: "4. Edit the photo with crop, add text, sticker & label on image if you needed",
              imgType: true,
              img: "260.Website-create-listing-crop – 1.jpg",
            },
          ],
          explanation: [
            {
              expTitle: "Crop :",
              expDesc:
                "Resize, rotate, scale the image, click correct mark once it done",
              imgType: true,
              img: "261.Website-create-listing-crop – 2.jpg",
            },
            {
              expTitle: "Text : ",
              expDesc:
                "1. Tap on “Type here” for enter copy, pinch with 2 finger to scaling the text box " +
                "<br/> 2. Select rectangular colour box for adding a colour box for text </br> " +
                "3. Choose the font style </br>" +
                "4. Select square colour box for changing colour of text</br> " +
                "5. Click correct mark once it done",
              imgType: true,
              img: "271.Website-create-listing-text – 2.jpg",
            },
            {
              expTitle: "Sticker :",
              expDesc: "Choose the sticker, pinch with 2 finger to scaling the sticker",
              imgType: true,
              img: "273.Website-create-listing-sticker – 1.jpg",
            },
            {
              expTitle: "Label : ",
              expDesc:
                "Select type of hashtag or location</br>a) Hashtag - Tap on hashtag, enter the copy and click correct mark once it done</br>" +
                "b) Location - Tap on location, allowing Kloser to access this device’s location\n",
              imgType: true,
              img: "275.Website-create-listing-label-1.jpg",
            },
            {
              expTitle: "",
              expDesc: "7. Click “ Next” once it complete",
              imgType: true,
              img: "276.Website-create-listing-label-2.jpg",
            },
            {
              expTitle: "",
              expDesc: "8. Enter the title of the ad, hashtag (click done on Keyboard to capture the word - optional) and where you currently based at (optional) and click “Publish” once it done",
              imgType: true,
              img: "278.Website-edit – 1.jpg",
            },
            {
              expTitle: "Add / change the profile details:",
              expDesc: "1. Click the “Manage” at the top of right corner",
              imgType: true,
              img: "200.Website profile – empty profile – 5.jpg",
            },
          ],
        },
      ],
    };
  },
};
</script>
